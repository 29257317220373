<template>
   <v-expand-transition>
      <v-form ref="form">
         <div v-if="typeof person.id === 'string' || person.id >= 0">
            <v-card-text class="pt-9">
               <v-row>
                  <v-col cols="12" md="6">
                     <v-text-field v-model="form.name" label="Name" placeholder="Nama Lengkap" outlined hide-details required :rules="[v => !!v]">
                        <template slot="append-outer">
                           <v-btn large height="56" width="70" icon tile @click="$refs.personCard.click()">
                              <v-avatar v-if="form.Card" tile height="54" width="70">
                                 <img :src="setUrlFromImage(form.Card)" alt="Card" style="object-fit: cover;" @error="() => { form.Card = '' }">
                              </v-avatar>
                              <v-icon v-else tile size="84">
                                 mdi-image-area
                              </v-icon>
                              <input ref="personCard" type="file" accept="image/*" alt="personCard" style="display:none;" @change="attachmentPreview">
                           </v-btn>
                           <v-btn large height="56" width="56" icon class="ml-3" @click="$refs.personFace.click()">
                              <v-avatar v-if="form.Face" size="56">
                                 <img :src="setUrlFromImage(form.Face)" alt="Face" style="object-fit: cover;" @error="() => { form.Face = '' }">
                              </v-avatar>
                              <v-icon v-else size="64">
                                 mdi-account-circle
                              </v-icon>
                              <input ref="personFace" type="file" accept="image/*" alt="personFace" style="display:none;" @change="attachmentPreview">
                           </v-btn>
                        </template>
                     </v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                     <v-text-field v-model="form.identity" label="ID Number" placeholder="Nomor Identitas" outlined hide-details required :rules="[v => !!v]" />
                  </v-col>
                  <v-col cols="12" md="3">
                     <v-text-field v-model="form.telegram" label="Telegram ID" placeholder="Kode Telegram" outlined hide-details required :rules="[v => !!v]" />
                  </v-col>
               </v-row>
               <v-row>
                  <v-col cols="12" md="3">
                     <v-text-field v-model="form.phone" label="Phone" placeholder="Nomor Handphone" outlined hide-details required :rules="[v => !!v]" />
                  </v-col>
                  <v-col cols="12" md="3">
                     <v-text-field v-model="form.email" label="Email" placeholder="Alamat Email" outlined hide-details required :rules="[v => /^\w+([-\+\.]?\w+)*@\w+([-\.]?\w+)*(\.\w{2,4})+$/.test(v)]" />
                  </v-col>
                  <v-col cols="12" md="3">
                     <v-text-field v-model="form.password" label="Password" placeholder="Kata Rahasia" outlined hide-details required :rules="[v => !!v]"
                        :type="(form.id===0&&seen)?'text':'password'" :append-icon="form.id===0?seen?'mdi-eye':'mdi-eye-off':''"
                        @click:append="seen=!seen"
                        />
                  </v-col>
               </v-row>
               <v-row>
                  <v-col cols="12" md="4">
                     <v-text-field :value="organization.name" label="Organization" placeholder="Nama Perusahaan" outlined hide-details readonly />
                  </v-col>
                  <v-col cols="12" md="8">
                     <v-chip-group active-class="accent--text" column style="margin-top: -7px; margin-bottom: -7px;">
                        <v-chip v-for="(tag, idx) in organization.Floors" :key="idx" large label outlined style="max-width: 220px;">
                           <v-icon left size="18">
                              mdi-stairs
                           </v-icon>
                           <v-list-item-content>
                              <v-list-item-title class="mb-0" style="line-height: 1.4rem;">
                                 {{ floorText(tag.identity) }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                 {{ tag.identity }}
                              </v-list-item-subtitle>
                           </v-list-item-content>
                        </v-chip>
                     </v-chip-group>
                  </v-col>
               </v-row>
            </v-card-text>
            <v-divider />
            <v-card-text class="pb-6">
               <v-row class="mt-0">
                  <v-col cols="12" class="py-0 d-flex">
                     <v-select v-model="form.status" :items="registrations" label="Status" placeholder="Pilih Status" outlined hide-details class="mr-4" style="margin-top: -2px; max-width: 290px;"
                        :return-object="false" :menu-props="{ bottom: true, offsetY: true }"
                        />
                     <v-btn v-if="$hasRole(person.kind.toLowerCase(), 'CU')" x-large color="accent" depressed class="mr-4" :loading="$store.state.process === 'updatePerson'" @click="updateRecord">
                        Update
                     </v-btn>
                     <v-btn x-large color="accent" depressed outlined class="mr-4" @click="person.id = -1">
                        {{ $store.state.process === 'updatePerson' ? 'Close' : 'Cancel' }}
                     </v-btn>
                     <v-spacer />
                  </v-col>
               </v-row>
            </v-card-text>
            <v-divider />
         </div>
      </v-form>
   </v-expand-transition>
</template>

<script>
import { imageFromUrl, blobImageToBase64 } from '@/utils/nirtara'
import { receptionist } from '@/utils/fields.js'
export default {
   name: 'NReceptionistForm',

   props: {
      person: { type: Object },
      organizations: { type: Array },
      floors: { type: Array },
   },
   data: () => ({
      isUsed: false,
      form: Object.assign({}, receptionist),
      seen: false,
      organization: { Floors: [] },
      registrations: [],
   }),

   computed: {
      setUrlFromImage () {
         return image => imageFromUrl(image)
      },
      floorText () {
         return identity => (this.floors.find(j => j.identity === identity) || { name: identity }).name
      },
   },
   watch: {
      'person.id' (id) {
         if (id < 0) return
         id === 0 && this.$refs.form.reset()
         this.form = Object.assign({}, receptionist, typeof id === 'string' ? this.person : { id: 0 })
         this.organization = { Floors: [] }
         if (id !== 0) {
            this.organization = this.organizations.find(j => j.identity === this.person.organization) || {}
            // console.log('watch (this.organization)', JSON.parse(JSON.stringify(this.organization)))
         }
      },
   },
   created () {
      this.registrations = [
         { text: 'Enroll', disabled: true }, // By RCP
         { text: 'Submit', disabled: true }, // By RCP
         { text: 'Canceled', disabled: true }, // By RCP
         { text: 'Active' }, // By TRO
         { text: 'Disabled' }, // By TRO
      ]
   },
   methods: {
      attachmentPreview (event) {
         blobImageToBase64(this.$refs[event.target.alt].files[0])
         .then(base64 => {
            switch (event.target.alt) {
               default: console.log(`${event.target.alt}: ${base64.substr(0, 32)}....`)
                  break
               case 'personCard': this.form.Card = base64
                  break
               case 'personFace': this.form.Face = base64
                  break
            }
         })
      },
      updateRecord () {
         if (!this.$refs.form.validate()) return
         this.$emit('update', this.form)
      },
   },
}
</script>

<style lang="scss" scoped>
.col-12 {
   padding: 0 12px 20px 12px;
}
::v-deep .v-text-field--outlined .v-input__append-outer {
   margin: auto 0 auto 16px;
}
.v-chip.v-chip--outlined .v-icon {
   font-size: 36px !important;
}
// ::v-deep .v-chip .v-chip__close.v-icon {
//    font-size: 22px !important;
// }
// .v-chip .v-avatar {
//    height: 40px !important;
//    min-width: 40px !important;
//    width: 40px !important;
//    margin-right: 10px;
// }
::v-deep .v-chip .v-chip__content {
   letter-spacing: 0;
}
.v-btn:not(.v-btn--round).v-size--x-large {
   min-width: 115px;
}
</style>
